<template>
  <div class="container">
    <nav-box title="登录" :back="false"></nav-box>
    <div class="content flex-column">
      <div class="item flex-column">
        <span>商户ID</span>
        <input
          type="text"
          v-model="tenantIdOrStoreId"
          placeholder="请输入商户ID"
        />
      </div>
      <div class="item flex-column">
        <span>登录账号</span>
        <input type="text" v-model="accountName" placeholder="请输入登录账号" />
      </div>
      <div class="item flex-column">
        <span>密码</span>
        <input type="passWord" v-model="password" placeholder="请输入密码" />
      </div>
      <div class="item flex-column">
        <span>登录功能</span>
        <select v-model="type" name="" id="">
          <option label="自助开牌">自助开牌</option>
          <option label="自助结账">自助结账</option>
          <option label="先付模式">先付模式</option>
        </select>
      </div>
      <div class="forget">忘记密码？</div>
      <div class="login flex flex-center" @click="login">登 录</div>
    </div>
  </div>
</template>

<script>
import navBox from "@/components/nav-box.vue";
export default {
  components: {
    navBox,
  },
  name: "login",
  data() {
    return {
      tenantIdOrStoreId: "",
      accountName: "",
      password: "",
      type: "自助开牌",
    };
  },
  methods: {
    doPrintText() {
      if (typeof Android2JS != "undefined") {
        Android2JS.Print3Lines();
        Android2JS.CutPaper();
      } else {
        this.$toast("未检测到打印硬件，已跳过打印");
      }
    },
    login() {
      console.log(this.type);
      if (this.tenantIdOrStoreId == "") {
        this.$toast("请输入商户ID");
        return false;
      } else if (this.accountName == "") {
        this.$toast("请输入账号");
        return false;
      } else if (this.password == "") {
        this.$toast("请输入密码");
        return false;
      } else {
        let data = {
          accountName: this.accountName,
          accountType: 1,
          password: this.password,
          tenantIdOrStoreId: this.tenantIdOrStoreId,
        };
        this.$post(this.Api.LoginApi, data).then((res) => {
          console.log(res);
          if (res.code == 1) {
            const { token, stores } = res.data[0];
            let store = stores.filter(
              (item) => this.tenantIdOrStoreId == item.storeId
            );
            console.log(store);
            if (store.length > 0) {
              localStorage.setItem("storeId", store[0].storeId);
              localStorage.setItem("storeName", store[0].storeName);
            } else {
              localStorage.setItem("storeId", stores[0].storeId);
              localStorage.setItem("storeName", stores[0].storeName);
            }
            localStorage.setItem("token", token);
            localStorage.setItem("type", this.type);
            this.getConsumptionAreaId()
            if (this.type == "自助结账") {
              this.$router.push("/welcome");
            } else if (this.type == '自助开牌') {
              this.$router.push("/openCard");
            }else{
              this.$router.push('/prepayment')
            }
          }
        }).catch(err => {
          alert(JSON.stringify(err))
        })
      }
    },
    getConsumptionAreaId() {
      this.$fetchNo(this.Api.userInfo).then((res) => {
        let dataList = res.data.filter((item) => item.isDefault);
        if (dataList.length > 0) {
          localStorage.setItem(
            "consumptionAreaId",
            dataList[0].consumptionAreaId
          );
        } else {
          localStorage.setItem(
            "consumptionAreaId",
            res.data[0].consumptionAreaId
          );
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.content {
  margin-top: 150px;
  padding: 0 198px;
  .item {
    margin-bottom: 56px;
    span {
      font-size: 44px;
      line-height: 64px;
      font-size: #1a1e22;
      margin-bottom: 32px;
    }
    input,
    select {
      width: 100%;
      height: 140px;
      background: #f4f4f4;
      box-shadow: inset 2px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      font-size: 44px;
      text-indent: 32px;
      color: #1a1e22;
      border: 0;
      &::placeholder {
        color: #b9bdc6;
      }
    }
  }
  .forget {
    width: 100%;
    font-size: 44px;
    line-height: 64px;
    color: #155bd4;
    text-align: right;
    margin-bottom: 140px;
  }
  .login {
    width: 100%;
    height: 140px;
    background: #155bd4;
    border-radius: 16px;
    font-size: 40px;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>